// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import layout from './layout'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import claim from '@src/views/claim/store/reducer'
import nhaDetails from '@src/views/claim/store/reducer/nha'


const rootReducer = combineReducers({
  auth,
  layout,
  claim,
  dataTables,
  nhaDetails
})

export default rootReducer
