// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedClaim: null,
  selectedTpaForForm: null,
  formTpaOptions: null,
  claimDraft: null,
  claimOthersDraft: null,
  claimDraftId:null,
  tpaList: [],
  claimDraftDocs:[],
  allData: [],
  emailService: null,
  allEmails: [],
  nextPageToken: null
}

const DataTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }

    case 'UPDATE_FILTER_DATA':
      return {
        ...state,
        data: action.data

      }

    case 'GET_DETAILS':
      return {
        ...state,
        selectedClaim: action.data,
        params: action.params
      }

    case 'GET_DRAFT':
      return {
        ...state,
        claimDraft: action.data,
        claimOthersDraft:action.data,
        claimDraftId: action.data.id,
        params: action.params
      }

    case 'GET_TPA_LIST':
      return {
        ...state,
        tpaList: action.data
      }

    case 'GET_TPA_OPTIONS':
      return {
        ...state,
        formTpaOptions: action.data,
        selectedTpaForForm: action.tpaId
      }

    case 'SAVE_CLAIM':
      return {
        ...state,
        claimDraft: action.data
      }

    case 'SAVE_MEDICAL':
      return {
        ...state,
        claimDraft: { ...state.claimDraft, medical: action.data }
      }
    case 'SAVE_PATIENT':
      return {
        ...state,
        claimDraft: { ...state.claimDraft, patient:action.data}
      }

    case 'DELETE_DOC_LIST':
      return {
        ...state,
        claimDraft: { ...state.claimDraft, document: action.data }
      }
    case 'SAVE_DOCUMENT':
      return {
        ...state,
        claimDraft: { ...state.claimDraft, document: action.data }
      }

    case 'DOCS_LIST':
      return {
        ...state,
        claimDraftDocs: action.data
      }
    
    case 'GET_EMAIL_DATA':
        localStorage.setItem('next_page_token', action.data.next_page_token)
        return {
          ...state,
          emailService: action.data.status ?? false,
          allEmails: action.data.emails ?? [],
          nextPageToken: action.data.next_page_token,
          params: action.params ?? ''
        }

    default:
      return state
  }
}

export default DataTablesReducer
