import { datadogRum } from "@datadog/browser-rum";
import Config from "@src/Config.js";

const ENV_NAME_MAP = {
  stage: "stage",
  uat: "uat",
  operations: "operations",
  zap: "prod"
};

const APP_ENV = ENV_NAME_MAP[window.location.hostname.split(".")[0]];

const initializeRum = () => {
  if (APP_ENV) {
    datadogRum.init({
      applicationId: Config.dataDogApplicationIdRUM,
      clientToken: Config.dataDogClientTokenRUM,
      site: "ap1.datadoghq.com",
      service: "healspan-web",
      env: APP_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input"
    });
  }
};

initializeRum();
